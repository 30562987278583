import $ from 'jquery';
import 'slick-carousel'; 
import 'slick-carousel/slick/slick.css';

// import helper from './libs/helper.js'; 
// import popup  from './libs/popup.js';

import { gsap, TweenLite } from "gsap/all";
gsap.registerPlugin(TweenLite); 


const home = {};

home.sliderHome = function(){
	const slider = $('.homeSlider .slider'),
			caps = slider.find(".caption");
    slider.slick({
    	arrows: true,
    	dots: true,
    	fade: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 800,
        pauseOnFocus: false,
        pauseOnHover: false,
        lazyLoad: 'ondemand',
    });

    if(slider.length){

        TweenLite.set(caps.find('h5'), {css: {'position': 'relative'}});
        TweenLite.set(caps.find('h2'), {css: {'position': 'relative'}});
        TweenLite.set(caps.find('h1'), {css: {'position': 'relative'}});
        TweenLite.set(caps.find('.wrap-button'), {css: {'position': 'relative'}});
    }
    slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        var caption = slider.find(".slick-slide[data-slick-index=" + nextSlide + "]");

        TweenLite.to(caption.find('h5'), 0.2, {css: {'left': '-50px','opacity': '0',},ease: 'Quart.easeOut'});
        TweenLite.to(caption.find('h2'), 0.2, {css: {'left': '-50px','opacity': '0',},ease: 'Quart.easeOut'});        
        TweenLite.to(caption.find('h1'), 0.2, {css: {'left': '-50px','opacity': '0',},ease: 'Quart.easeOut'});        
        TweenLite.to(caption.find('.wrap-button'), 0.2, {css: {'left': '-50px','opacity': '0',},ease: 'Quart.easeOut'});        
    });
    slider.on('afterChange', function(event, slick, currentSlide){
        var caption = slider.find(".slick-slide[data-slick-index=" + currentSlide + "]");

        TweenLite.to(caption.find('h5'), 0.5, {css: {'left': '0','opacity': '1',},delay: 0.2,ease: 'Quart.easeOut'});
        TweenLite.to(caption.find('h2'), 0.5, {css: {'left': '0','opacity': '1',},delay: 0.4,ease: 'Quart.easeOut'});        
        TweenLite.to(caption.find('h1'), 0.5, {css: {'left': '0','opacity': '1',},delay: 0.4,ease: 'Quart.easeOut'});        
        TweenLite.to(caption.find('.wrap-button'), 0.5, {css: {'left': '0','opacity': '1',},delay: 0.6,ease: 'Quart.easeOut'});        
    });
}


home.init = function() { 
    home.sliderHome()
}

home.init();

// window.$ = $
// $(document).ready(() => {
//   popup($).init()
// })